import React from 'react'
import Layout from '../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"
import CCBreadcrumb from '../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import Introduction from '../components/page-sections/introduction'

import rowImage from '../queries/images/row-image'

import unit1Icon from '../images/icons/unit_01_icon.svg'
import unit2Icon from '../images/icons/unit_02_icon.svg'
import unit3Icon from '../images/icons/unit_03_icon.svg'


const IntroductionToUnits = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - Introduction to Units" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Introduction to Units'}
      replace={props.replace}
    />
    <Container className="mb-5" fluid>
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image1.childImageSharp.fluid}
                          alt="People talking"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageLeft rounded mr-3 mb-2 img-fluid d-none d-md-block"
                      fluid={props.data.image1.childImageSharp.fluid}
                      alt="People talking"
                    />
                    <p>
                      Questions about climate are not local questions. Climate topics are also global. They can affect us all in some way. We are all linked through interactions among the land, oceans, and atmosphere. Even though climate can affect you, the reverse is also true. You can affect climate, and what you and your friends do will have a small&mdash;but global&mdash;impact. Because of this, you can make a difference.
                    </p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>
                      In <em>Carbon Connections</em> there are three units where you will learn about the carbon cycle and the science of climate. Some activities and investigations will be done on this website, and some will be done in class with your teacher and classmates. The topics covered in <em>Carbon Connections</em> will tie to things that you have been learning about in science. If you have taken courses in biology, geology, or environmental science, for example, you will recognize some of the ideas in these units. The units also relate closely to many current topics in science and society.
                    </p>
                  </>
                }

                paragraphThree={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image2.childImageSharp.fluid}
                          alt="People talking"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight rounded ml-2 mb-2 img-fluid d-none d-md-block"
                      fluid={props.data.image2.childImageSharp.fluid}
                      alt="Earth"
                    />

                    <p>
                      Start by completing the following task. Take out your science notebook, and write the title <strong><em>Carbon Connections</em></strong>, along with today's date. Then answer the questions below. <em>You can discuss your ideas with a partner, but you must write your own answers in your notebook.</em>
                    </p>
                    <ol>
                      <li>Name as many different kinds of substances with carbon as you can. If you know, write where they are found in the Earth system (for example: carbon is found in the ground as coal layers).</li>
                      <li>Have you studied the carbon cycle in your science class? If so, what are at least three things you remember about the carbon cycle?</li>
                      <li>How do you think the carbon cycle and climate science are related?</li>
                      <li>Have you ever considered a career in a science, engineering, or medical field? What kind of career in one of these fields sounds interesting to you?</li>
                      <li>How do you think that the carbon cycle might be related to the electricity at your home or school?</li>
                    </ol>

                    <p>
                      Continue by reading the section below. It is a brief summary of the units you will experience in <em>Carbon Connections</em>.
                    </p>
                  </>
                }
              />

              <div className="grid-row">
                <Row>
                  <Col>
                    <Card className="mb-4">
                      <Card.Header className="cardHeader">
                        <div className="d-flex">
                          <div className="mr-3">
                            <img
                              className="img-fluid unitIcon"
                              src={ unit1Icon }
                              alt="Unit 1 icon"
                            />
                          </div>
                          <div>
                            <h2 style={{ lineHeight: '1' }}>Unit 1: <span className="headerFontBlue">Carbon &amp; Climate in the Past</span></h2>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="cardBodyBackground">
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={props.data.image3.childImageSharp.fluid}
                              alt="Mountains"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight rounded ml-2 mb-2 img-fluid d-none d-md-block"
                          fluid={props.data.image3.childImageSharp.fluid}
                          alt="Mountains"
                        />

                        <p>
                          In Unit 1, you will explore the idea that the past is the key to the present. The reason is that explanations for climate change in the past give vital clues to understanding climate change today. Some periods in the geologic past were warmer than today; other times, such as the Ice Ages, were much colder than today. Geoscientists study how changes between cold and warm periods relate to the carbon cycle and to gain evidence of climate change.
                        </p>

                      </Card.Body>
                      <Card.Footer>
                        <Link to="/unit-1/1.0-overview/">
                          <Button className="btn-primary" >
                            Go to Unit 1&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                          </Button>
                        </Link>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="grid-row">
                <Row>
                  <Col>
                    <Card className="mb-4">
                      <Card.Header className="cardHeader">
                        <div className="d-flex">
                          <div className="d-block mr-3">
                            <img
                              className="img-fluid unitIcon"
                              src={ unit2Icon }
                              alt="Unit 2 icon"
                            />
                          </div>
                          <div>
                            <h2 style={{ lineHeight: '1' }}>Unit 2: <span className="headerFontBlue">Carbon Now</span></h2>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="cardBodyBackground">
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={props.data.image4.childImageSharp.fluid}
                              alt="Earth"
                            />
                          </Col>
                        </Row>
                          <Img
                            className="smallImageRight rounded ml-2 mb-2 img-fluid d-none d-md-block"
                            fluid={props.data.image4.childImageSharp.fluid}
                            alt="Earth"
                          />

                        <p>
                          In science, you have likely studied food webs. You may have used words like <em>primary producers (autotrophs)</em>, and <em>consumers (heterotrophs)</em>. These organisms exchange carbon with their surroundings by photosynthesis and respiration. Think about a park, field, or forest near your house. Consider all the plants, animals, and bacteria that live there. You must include organisms in the soil, too. How much carbon do you think that all of these organisms exchange with their surroundings? What about the carbon that all the organisms in the oceans exchange with their surroundings?
                        </p>

                        <p>
                          In Unit 2, you will investigate how living things, including humans, are part of the carbon cycle. Every day, NASA satellites collect data around the globe to help us understand how the carbon cycle works. You will also consider the consequences of burning carbon that was stored in living things millions of years ago. To test how the amount of carbon changes between land-air-oceans, you will use some models. These models will show you how carbon moves in and out of organisms on a global scale.
                        </p>

                      </Card.Body>
                      <Card.Footer>
                        <Link to="/unit-2/2.0-overview/">
                          <Button className="btn-primary">
                            Go to Unit 2&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                          </Button>
                        </Link>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="grid-row">
                <Row>
                  <Col>
                    <Card className="mb-4">
                      <Card.Header className="cardHeader">
                        <div className="d-flex">
                          <div className="mr-3">
                            <img
                              className="img-fluid unitIcon"
                              src={ unit3Icon }
                              alt="Unit 3 icon"
                            />
                          </div>
                          <div>
                            <h2 style={{ lineHeight: '1' }}>Unit 3 :<span className="headerFontBlue">Carbon in the Future &amp; You</span></h2>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body className="cardBodyBackground">
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={props.data.image5.childImageSharp.fluid}
                              alt="Nuclear power plant cooling tower"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight rounded ml-4 img-fluid d-none d-md-block"
                          fluid={props.data.image5.childImageSharp.fluid}
                          alt="Nuclear power plant cooling tower"
                        />

                        <p>
                          Geoscientists measure and monitor different parts of the Earth system. From their data, they can test whether certain factors make other factors change. One way to do this is by using different types of models. That is how they can learn about connections between the carbon cycle and climate.
                        </p>

                        <p>
                          In Unit 3, you will manipulate some models to test what factors may make Earth's temperature change. These models also help scientists predict future events. The model you will use is based on the latest global data, which were compiled by many hundreds of scientists and researchers. Many of these workers are with NASA, in teams working to monitor Earth systems.
                        </p>

                        <p>
                          With the model, you will be able to test authentic scientific questions. Some factors are related to natural events, such as massive volcanic eruptions or variation in the sun's radiation. Another factor relates to humans. For any of the climate factors, you will see that learning how to critically evaluate claims about climate is an important part of being an informed citizen.
                        </p>

                      </Card.Body>
                      <Card.Footer>
                        <Link to="/unit-3/3.0-overview/">
                          <Button className="btn-primary">
                            Go to Unit 3&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                          </Button>
                        </Link>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IntroductionToUnits

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "student-pages/introduction-to-units/01_people_talking.jpg" }) {
      ...rowImage
    }
    image2: file(relativePath: { eq: "student-pages/introduction-to-units/02_earth.jpg" }) {
      ...rowImage
    }
    image3: file(relativePath: { eq: "student-pages/introduction-to-units/03_mountains.jpg" }) {
      ...rowImage
    }
    image4: file(relativePath: { eq: "student-pages/introduction-to-units/04_earth_carbon_image.jpg" }) {
      ...rowImage
    }
    image5: file(relativePath: { eq: "student-pages/introduction-to-units/05_powerplant.jpg" }) {
      ...rowImage
    }
  }
`
